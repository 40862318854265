import React from "react";
import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import Components from "./components.js";
import { isi } from "./isi/isi.js";
import { isiSafety } from "./isi/isi.js";
import { createRoot } from "react-dom/client";
import "./styles.scss";
import ISIComponent from "./components/ISI/ISI.js";
import moreLink from "./assets/img/more.png";
import { useGTM } from "./components/GTM/useGTM";


const data = {
  content: {
    body: [
      {
        _uid: "4",
        component: "TitleBlockBackground",
        text: `A Closer Look at Guselkumab,<br> a Fully Human IL-23<br> Monoclonal Antibody`,
      },

      {
        _uid: "4x",
        component: "Menu",
        headline: "Menu",
      },
      {
        _uid: "4xx",
        component: "EyebrowHeadlineText",
        text: "Guselkumab was approved in 2017 for the treatment of adults with moderate to severe plaque psoriasis who are candidates for systemic therapy or phototherapy, and was approved in 2020 for the treatment of adult patients with active psoriatic arthritis.<sup>1</sup>",
        footnotes: [
          `1. TREMFYA<sup>®</sup> (guselkumab) Prescribing Information. Horsham, PA. Janssen Biotech Inc.`,
        ],
      },
      {
        _uid: "5",
        component: "TitleWithIcon",
        headline: "Monoclonal Antibodies: Structure & Development",
        hr: true,
        anchor: "monoclonal",
      },
      {
        _uid: "5x",
        component: "EyebrowHeadlineText",
        mainheadline: "Monoclonal Antibody Structure<sup>1</sup>",
        image: "f2",

        footnotes: [
          `CD=complementarity-determining; Fab=antigen binding fragment; Fc=crystallizable fragment.`,
          `1. Chiu ML, et al. <i>Antibodies</i>. 2019;8(4):55.`,
        ],
      },
      {
        _uid: "6",
        component: "EyebrowHeadlineText",
        mainheadline: "Development of Monoclonal Antibodies<sup>1-4</sup>",
        text: "Monoclonal antibodies (mAbs) are designed to mimic or inhibit the action of natural proteins and suppress a specific part of the immune system. There are four types of mAbs categorized according to their origin: murine, chimeric, humanized, and fully human. The origin of a mAb can influence immunogenicity.",
        image: "f3",
        footnotes: [
          `Fc=crystallizable fragment; IgG=immunoglobulin G. `,
          `1. Valenzuela F, et al. <i>Clinics</i>. 2021;76:e3015. 2. US FDA. Immunogenicity Assessment for Therapeutic Protein Products. Accessed Oct 21, 2022. Updated Aug 2014. https://www.fda.gov/regulatory-information/search-fda-guidance-documents/immunogenicity-assessment-therapeutic-protein-products. 3. Van Hoecke L, et al. <i>J Transl Med</i>. 2019;17(1):54. 4. Chames P, et al. <i>Br J Pharmacol</i>. 2009;157(2):220-233.`,
        ],
      },
      {
        _uid: "7",
        component: "EyebrowHeadlineText",
        mainheadline: "Types of Monoclonal Antibodies<sup>1-5</sup>",
        image: "f4",
        textUnderImage: `<br/>The four types of monoclonal antibodies named and characterized by origin.`,

        footnotes: [
          `1. Valenzuela F, et al. <i>Clinics</i>. 2021;76:e3015. 2. US FDA. Immunogenicity Assessment for Therapeutic Protein Products. Accessed Oct 21, 2022. Updated Aug 2014. https://www.fda.gov/regulatory-information/search-fda-guidance-documents/immunogenicity-assessment-therapeutic-protein-products. 3. Van Hoecke L, et al. <i>J Transl Med</i>. 2019;17(1):54. 4. Chames P, et al. <i>Br J Pharmacol</i>. 2009;157(2):220-233. 5. Mayrhofer P, et al. <i>Hum Antibodies</i>. 2019;27(1):37-51.`,
        ],
      },
      {
        _uid: "8",
        component: "TitleWithIcon",
        headline:
          "Guselkumab Mechanism of Action <span class='make-inline'>and Binding</span>",
        hr: true,
        anchor: "guselkumab",
      },

      {
        _uid: "8x",
        mainheadline:
          "Guselkumab Mechanism of Action and Binding to IL-23 and CD64<sup>1-6</sup>",
        component: "EyebrowHeadlineText",
        trueIfNoSpaceAbove: true,
        h4BottomZero: true,
        trueIfPaddingBelowContainer: true,
        textUnderDiscover: `Moderate to severe plaque psoriasis (PsO) and active psoriatic arthritis (PsA) are understood to be disorders of the immune system driven primarily by dysregulated <span class="make-inline">T cells</span>. `,
        bullets: [
          `Click on the play button to start a video that shows the mechanism of action of guselkumab, the first fully human IL-23 inhibitor`,
          `We focus on the IL-23/Th17 axis, a pathway involved in the pathogenesis of plaque PsO and active PsA. We review the binding of guselkumab to the p19 subunit on IL-23 and to CD64 on mononuclear phagocytes. These cells are a major source of IL-23 in the pathogenesis of plaque PsO and active PsA`,
        ],
        footnotes: [
          `CD=cluster of differentiation; IL=interleukin; Th=T-helper cell.`,
          `1. Alunno A, et al. <i>Expert Opin Biol Ther</i>. 2015;15(12):1727-1737. 2. Tang C, et al. <i>Immunology</i>. 2012;135(2):112-124.                                3. TREMFYA<sup>®</sup> (guselkumab) [Prescribing Information]. Horsham, PA: Janssen Biotech, Inc. 4. Akinrinmade OA, et al. <i>Biomedicines</i>. 2017;5(3):56. 5. Wang Y, et al. <i>Sci Rep</i>. 2019;9(1). 6. Krueger J, et al. Poster presented at: Society for Investigative Dermatology Annual Meeting; May 18-21, 2022; Portland, OR. #LB989.`,
        ],
      },
      {
        _uid: "8xx",
        component: "Video",
      },

      {
        _uid: "8xxxx",
        component: "EyebrowHeadlineText",
        divImage: "gordon-lam",
        textInImage:
          "<b>Narrated by Gordon Lam, MD</b><br> Medical Director of Clinical Research for the Arthritis and Osteoporosis Consultants of the Carolinas and an affiliate of Atrium Health, the Wake Forest School of Medicine in Charlotte, NC",
        textUnderImage:
          "<span style='display:inline-block'>This promotional educational activity is brought to you by Janssen and is not certified for continuing medical education. The consultant is a paid speaker for Janssen. The speaker is presenting on behalf of Janssen and must present information in compliance with FDA requirements applicable to Janssen.</span>",
      },
      {
        _uid: "9",
        component: "EyebrowHeadlineText",
        image: "f5",
        footnotes: [
          `<b>*Other cytokines that facilitate differentiation of Th17 cells include TGF-β, IL-1, and IL-6, while IL-23 promotes survival and expansion of Th17 cells.<sup>7</sup></b>`,
          `<sup>†</sup>Serum levels of IL-17A, IL-17F, and IL-22 measured at Week 4 and Week 24 were decreased compared to baseline following treatment with guselkumab.`,
        ],
      },
      {
        _uid: "9x",
        component: "EyebrowHeadlineText",
        textUnderImage: [
          `Guselkumab is a human monoclonal IgG1λ antibody that selectively binds to the p19 subunit of IL-23 and inhibits its interaction with the IL-23 receptor.<sup>1</sup> IL-23 is a naturally occurring cytokine that is involved in normal inflammatory and immune responses. Guselkumab inhibits the release of proinflammatory cytokines and chemokines.<sup>1</sup>
        <br><br>
        Monoclonal antibodies with wild-type Fc regions, such as guselkumab, can bind to CD64 via the Fc region of the antibody.<sup>8,9</sup> Guselkumab binds CD64 with its Fc region while also binding secreted IL-23 via the antigen binding domain.<sup>9</sup>
        <br><br>
        In evaluated subjects with plaque PsO, guselkumab reduced serum levels of IL-17A, <span class="make-inline">IL-17F</span>, and IL-22 relative to pretreatment levels based on exploratory analyses of the pharmacodynamic markers.<sup>1</sup> In evaluated subjects with active PsA, serum levels of acute phase proteins (C-reactive protein, serum amyloid A, and IL-6) and Th17 effector cytokines <span class="make-inline">(IL-17A, IL-17F, and IL-22)</span> were elevated at baseline.<sup>1</sup> Serum levels of these proteins measured at Week 4 and Week 24 were decreased compared with baseline following guselkumab treatment at Week 0, Week 4, and every 8 weeks thereafter.<sup>1</sup>
        <br><br>
        <b>The clinical significance of these findings is not known</b>. The relationship between these pharmacodynamic markers and the mechanism(s) by which guselkumab exerts its clinical effects is unknown.
 `,
        ],
        footnotes: [
          `CD=cluster of differentiation; Fc=crystallizable fragment; IgG1λ=immunoglobulin G1 lambda; IL=interleukin; PsA=psoriatic arthritis; PsO=psoriasis; TGF-β=transforming growth factor beta; Th=T-helper cell.`,
          `1. TREMFYA<sup>®</sup> (guselkumab) [Prescribing Information]. Horsham, PA: Janssen Biotech, Inc. 2. Data on file. Janssen Biotech, Inc. 3. Zhuang Y, et al. <i>Eur J Clin Pharmacol</i>. 2016;72(11):1303-1310. 4. Nestle FO, et al. <i>N Engl J Med</i>. 2009;361:496-509. 5. Fitch E, et al. <i>Curr Rheumatol Rep</i>. 2007;9(6):461-467. 6. Fujita H. <i>J Dermatol Sci</i>. 2013;72(1):3-8. 7. Boutet MA, et al. Int <i>J Mol Sci</i>. 2018;19:530. 8. Zhou L, et al. <i>MAbs</i>. 2021;13(1):1964420. <span class="make-inline">9. Krueger J, et al. Poster presented at: Society for Investigative Dermatology Annual Meeting;</span> May 18-21, 2022; Portland, OR. #LB989.`,
        ],
      },
      {
        _uid: "10",
        component: "Question",
        questionNumber: "1",
        question:
          "Does the mechanism of action (MoA) of a monoclonal antibody (mAb) affect your decision-making about using the mAb?",
        options: [
          `The MoA of a mAb has no effect on my decision to use it`,
          `The MoA of a mAb has little effect on my decision to use it`,
          `The MoA of a mAb has a moderate effect on my decision to use it`,
          `The MoA of a mAb has a significant effect on my decision to use it`,
        ],

        answer: null,
        title: "Question 1",
      },
      {
        _uid: "11",
        component: "EyebrowHeadlineText",
        trueIfSpaceAbove: true,
        mainheadline: "Structure of Guselkumab",
        image: "f6",
        footnotes: [
          `*100% human protein.<sup>3-6</sup> Guselkumab, an IL-23 blocker, is a human IgG1λ monoclonal antibody. Guselkumab is produced in a mammalian cell line using recombinant DNA technology. Guselkumab selectively binds to the p19 subunit of IL-23 and inhibits its interaction with the IL-23 receptor. IL-23 is a naturally occurring cytokine that is involved with normal inflammatory and immune responses. Guselkumab inhibits the release of proinflammatory cytokines and chemokines.<sup>3</sup>`,
          `<br>CD region=complementarity-determining region; Fab=fragment antigen-binding; Fc=crystallizable fragment; IgG1λ=immunoglobulin G1 lambda; IL=interleukin.`,
          `1. Chames P, et al. <i>Br J Pharmacol</i>. 2009;157(2):220-233. 2. Van Hoecke L, Roose K. <i>J Transl Med</i>. 2019;17(1):54. <span class="make-inline">3. TREMFYA<sup>®</sup> (guselkumab)</span> [Prescribing Information]. Horsham, PA. Janssen Biotech Inc. 4. Al-Salama ZT, et al. <i>Am J Clin Dermatol</i>. 2018;19(6):907-918. 5. Krueger J, et al. Poster presented at: Society for Investigative Dermatology Annual Meeting; May 18-21, 2022; <span class="make-inline">Portland, OR. #LB989.</span> 6. Alfaleh MA, et al. <i>Front Immunol</i>. 2020;11:1986.`,
        ],
      },
      {
        _uid: "12",
        component: "EyebrowHeadlineText",
        trueIfSpaceAbove: true,
        mainheadline: "Guselkumab Dually Binds to IL-23 and CD64",
        image: "f7",
      },
      {
        _uid: "12x",
        component: "EyebrowHeadlineText",
        text: "Guselkumab is a fully human monoclonal antibody that selectively binds to the p19 subunit of IL-23 via its antigen-binding domain and inhibits its interaction with the IL-23 receptor.<sup>1,2</sup> Guselkumab also binds to CD64 expressed on inflammatory monocytes with its Fc region.<sup>2,3</sup> CD64 is a receptor found on the surface of monocytes and macrophages that bind to IgG antibodies.<sup>4,5</sup> CD64+ monocyte-like cells are the major IL-23–producing cells in inflamed tissues seen in preclinical PsO and PsA models.<sup>4-6</sup> Dual binding allows guselkumab to neutralize IL-23 locally at the source of production while also binding <span class='make-inline'>IL-23</span> that is released from the same cell via its antigen-binding domain.<sup>2,3</sup> <b>The clinical significance of these findings is not known</b>. ",
        footnotes: [
          `CD=cluster of differentiation; Fc=crystallizable fragment; IgG=immunoglobulin G; IL=interleukin; PsA=psoriatic arthritis; PsO=psoriasis.`,
          `1. TREMFYA<sup>®</sup> (guselkumab) [Prescribing Information]. Horsham, PA. Janssen Biotech Inc. 2. Krueger J, et al. Poster presented at: Society for Investigative Dermatology Annual Meeting; May 18-21, 2022; Portland, OR. #LB989. <span class="make-inline">3. Zhou L, et al.</span> <i>MAbs</i>. 2021;13(1):1964420. 4. Wang Y, et al. <i>Sci Rep</i>. 2019;9(1):5310. 5. Matt P, et al. <i>Scand J Rheumatol</i>. 2015;44(6):464-473. 6. Mehta H, et al. <i>J Invest Dermatol</i>. 2021;141(7):1707-1718.e9.`,
        ],
      },
      {
        _uid: "13",
        component: "EyebrowHeadlineText",
        trueIfSpaceAbove: true,
        mainheadline: "Guselkumab and IL-23 Binding Kinetics<sup>1</sup>",
        image: "f8",
        textUnderImage: [
          `This graph illustrates the binding kinetics of IL-23 with guselkumab, and indicates a high affinity, based on the K<sub>D</sub> of 1.0 +/- 1.1 pM.<sup>*</sup> Guselkumab binds and neutralizes IL-23 with high affinity and potency. <b>The clinical significance of these findings is not known.</b>
          `,
        ],
        footnotes: [
          `
          *Findings are limited to <i>in vitro</i> studies. Binding affinities at 22°C for anti-IL-23 p19 antibodies of guselkumab were directly compared using kinetic exclusion assay; K<sub>D</sub> is shown as mean ± standard deviation (n=3).`,
          `IL=interleukin; K<sub>D</sub>=equilibrium dissociation constant; M=molar; pM=picomolar.`,
          `1. Krueger J, et al. Poster presented at: Society for Investigative Dermatology Annual Meeting; May 18-21, 2022; Portland, OR. #LB989.`,
        ],
      },
      {
        _uid: "14",
        component: "EyebrowHeadlineText",
        trueIfSpaceAbove: true,
        mainheadline: "Guselkumab and CD64 Binding Kinetics<sup>1</sup>",
        image: "f9",
        textUnderImage: [
          `The <i>in vitro</i> binding affinity for the antigen recognition domains of guselkumab was studied. Human peripheral blood mononuclear cells were stimulated with IFNγ in the presence or absence of guselkumab. Flow cytometry using an HTRF assay was used to measure binding of guselkumab to IFNγ-primed monocytes. Guselkumab showed <span class="make-inline">dose-dependent</span> binding to CD64+ on human monocytes. Guselkumab binding to CD64+ on human monocytes does not induce activation (cytokine production).  <b>The clinical significance of these findings is not known.</b> `,
        ],
        footnotes: [
          `CD=cluster of differentiation; Fc=crystallizable fragment; HTRF=homogeneous time-resolved fluorescence; IFNγ=interferon gamma.`,
          `1. Krueger J, et al. Poster presented at: Society for Investigative Dermatology Annual Meeting; May 18-21, 2022; Portland, OR. #LB989.`,
        ],
      },
      {
        _uid: "15",
        component: "Question",
        questionNumber: "2",
        question:
          "Guselkumab’s mechanism of action<sup>1,2</sup> includes which of the following?",
        options: [
          `IL-23 only`,
          `CD64 only`,
          `IL-23 and CD64`,
          `IL-23, IL-12, and CD64`,
        ],
        title: "Question 2",
        answer: 2,
        footnote:
          "CD=cluster of differentiation; IL=interleukin.<br>1. Wang Y, et al. <i>Sci Rep</i>. 2019;9(1):5310. 2. Matt P, et al. <i>Scand J Rheumatol</i>. 2015;44(6):464-473.",
      },
      {
        _uid: "16",
        component: "Question",
        questionNumber: "Satisfaction",
        question:
          "How much did you learn from this therapeutic update about guselkumab binding?",
        options: [
          `I learned a little bit`,
          `I learned a lot `,
          `I am already familiar with this topic`,
        ],

        answer: null,
        title: "Satisfaction Question",
      },
      {
        _uid: "18",
        headline: "Past Therapeutic Updates",
        gradient: true,

        component: "TitleWithIcon",
        hr: true,
      },
    ],
  },
};

function App() {
  useGTM("GTM-PD7QX3T", "globalDataLayer");
  return (
    <div className="App">
      {data.content.body.map((block) => Components(block))}
      <p
        style={{
          padding: "1.2em",
          paddingBottom: "0.8em",
          backgroundColor: "#dff5fc",
          textAlign: "left",
        }}
      >
        Click on the image below to review guselkumab efficacy and safety data
        in active psoriatic arthritis and moderate to severe plaque psoriasis.
      </p>

      <a
        href="https://rheumnow.com/therapeutic-update/anti-il-23-active-psoriatic-arthritis-and-moderate-severe-plaque-psoriasis"
        target="_blank"
        rel="noreferrer"
        class={"alt-link"}
        onClick={() => {
          let dbref = ref(getDatabase(app), "/rheumnowBottomLinkClicked");
          set(dbref, {
            count: increment(1),
          });
        }}
      >
        <img style={{ maxWidth: "100%" }} src={moreLink}></img>
      </a>
      <br></br>
      <br></br>
      <br></br>
      <div
        style={{
          padding: "1.2em",
          paddingBottom: "0.8em",
          backgroundColor: "#dff5fc",
          textAlign: "left",
        }}
      />
      <hr />
      <br></br>
      <br></br>

      <b>
        Please read the full{" "}
        <a
          class={"alt-link"}
          target="_blank"
          href="https://www.janssenlabels.com/package-insert/product-monograph/prescribing-information/TREMFYA-pi.pdf"
          onClick={() => logLinkClick("Prescribing Information")}
        >
          Prescribing Information
        </a>{" "}
        and{" "}
        <a
          class={"alt-link"}
          target="_blank"
          href="https://www.janssenlabels.com/package-insert/product-patient-information/TREMFYA-medication-guide.pdf"
          onClick={() => logLinkClick("Medication Guide")}
        >
          Medication Guide
        </a>{" "}
        for guselkumab at{" "}
        <a
          class={"alt-link"}
          target="_blank"
          href="https://www.tremfyahcp.com"
          onClick={() => logLinkClick("TremfyaHCP")}
        >
          TremfyaHCP.com
        </a>
        . Provide the Medication Guide to your patients and encourage
        discussion.
      </b>
      <p>
        The material on this site is intended only as informational or as an
        educational aid and it is not intended to be taken as medical advice.
        The ultimate responsibility for patient care resides with a healthcare
        professional. Third party trademarks used herein are trademarks of their
        respective owners. <br />
        <br />
        <span class="make-inline">
          © Janssen Biotech, Inc. 2023 02/23 cp-352951v1
        </span>
      </p>
      <br></br>
      <br></br>
      <br></br>
      <ISIComponent />
    </div>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
