import React from "react";
import styles from "./Menu.scss";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, increment } from "firebase/database";
import { firebaseConfig } from "../helpers.js";
const app = initializeApp(firebaseConfig);

export default (props) => (
  <>
    <div className="menu">
      <a
        onClick={() => {
          let dbref = ref(getDatabase(app), "/menu/" + "monoclonal");
          set(dbref, {
            count: increment(1),
          });
        }}
        className="menu_item"
        href="#monoclonal"
      >
        <div className="menu_item--text">
          <span>Monoclonal Antibodies: Structure & Development</span>
        </div>
      </a>
      <a
        onClick={() => {
          let dbref = ref(getDatabase(app), "/menu/" + "structure");
          set(dbref, {
            count: increment(1),
          });
        }}
        className="menu_item"
        href="#guselkumab"
      >
        <div className="menu_item--text">
          <span> Guselkumab Mechanism of Action and Binding</span>
        </div>
      </a>
    </div>
  </>
);
