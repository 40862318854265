import React from "react";
import Menu from "./components/Menu/Menu";
import TitleBlockBackground from "./components/TitleBlockBackground/TitleBlockBackground";
import EyebrowHeadlineText from "./components/EyebrowHeadlineText/EyebrowHeadlineText";
import TitleWithIcon from "./components/TitleWithIcon/TitleWithIcon";
import Video from "./components/Video/Video";
import Question from "./components/Question/Question";

const Components = {
  TitleBlockBackground: TitleBlockBackground,
  Menu: Menu,
  EyebrowHeadlineText: EyebrowHeadlineText,
  TitleWithIcon: TitleWithIcon,
  Video: Video,
  Question: Question,

};

export default (block) => {
  if (typeof Components[block.component] !== "undefined") {
    return React.createElement(Components[block.component], {
      key: block._uid,
      block: block,
    });
  }
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    { key: block._uid }
  );
};
