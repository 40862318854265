import React from 'react';
import ReactDOM from 'react-dom';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import  "./Video.scss";

export default (props) => {

  return(
  <>

    <div id={props.block.anchor}>
        <ReactPlayerLoader
          accountId="4317630935001" videoId="6326619829112" />
      </div>
  </>
);
}
