import React from "react";
import styles from "./EyebrowHeadlineText.scss";

export default (props) => (
  <>
    {props.block.hrTop ? (
      <>
        <br />
        <br />
        <br />
        <br />
        <hr />
        <br />
      </>
    ) : null}
    <div
      className="EyebrowHeadlineText"
      style={
        props.block.trueIfPaddingBelowContainer ? { paddingBottom: "20px" } : {}
      }
    >
      {props.block.textTop ? (
        <div
          dangerouslySetInnerHTML={{ __html: props.block.textTop }}
          className="EyebrowHeadlineText--textTop"
        ></div>
      ) : null}
      {props.block.mainheadline ? (
        <h3
          style={props.block.trueIfSpaceAbove ? { marginTop: "4rem" } : {}}
          dangerouslySetInnerHTML={{ __html: props.block.mainheadline }}
        ></h3>
      ) : null}

      <h5
        dangerouslySetInnerHTML={{ __html: props.block.eyebrow }}
        style={
          props.block.trueIfNoSpaceAbove
            ? { marginTop: "10px" }
            : { marginBottom: "0" }
        }
      ></h5>
      <h4
        style={props.block.h4BottomZero ? { marginBottom: "0" } : {}}
        dangerouslySetInnerHTML={{ __html: props.block.headline }}
      ></h4>
      {props.block.discover ? (
        <img className={props.block.discover} alt="" />
      ) : null}
      {props.block.textUnderDiscover ? (
        <p
          dangerouslySetInnerHTML={{ __html: props.block.textUnderDiscover }}
        ></p>
      ) : null}
      {props.block.imageTop ? (
        <div className="WideImageBlock">
          <img className={props.block.imageTop} alt="" />
        </div>
      ) : null}
      {props.block.imageTop ? (
        <p
          dangerouslySetInnerHTML={{ __html: props.block.textAboveBullets }}
        ></p>
      ) : null}
      {props.block.bullets ? (
        <div className="GraphBullets">
          <ul>
            {props.block.bullets.map((footnote, index) =>
              footnote == "/n" ? (
                <br />
              ) : (
                <li
                  dangerouslySetInnerHTML={{ __html: footnote }}
                  key={index}
                ></li>
              )
            )}
          </ul>
        </div>
      ) : null}
      <p dangerouslySetInnerHTML={{ __html: props.block.text }}></p>
      {props.block.image ? (
        <div className="WideImageBlock">
          <img className={props.block.image} alt="" />
        </div>
      ) : null}
      {props.block.textInImage ? (
        <div className={props.block.divImage}>
          <p dangerouslySetInnerHTML={{ __html: props.block.textInImage }}></p>
        </div>
      ) : null}
      <p dangerouslySetInnerHTML={{ __html: props.block.textUnderImage }}></p>
      {/* <h3>{props.block.headline}</h3> */}
      {/* <img className={props.block.image} alt="" /> */}
      {props.block.footnotes ? (
        <div className="Footnotes">
          {props.block.footnotes.map((footnote, index) =>
            footnote == "/n" ? (
              <br />
            ) : (
              <div
                className="text" //dangeorously set html react
                dangerouslySetInnerHTML={{ __html: footnote }}
                key={index}
              ></div>
            )
          )}
        </div>
      ) : null}
    </div>
  </>
);
