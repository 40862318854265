import React from "react";
import styles from "./TitleBlockBackground.scss";
import icon from "../../assets/img/header.png";

export default (props) => (
  <>

<div className="TitleBlockBackground">
      <div className="gradLineTop" />
      <h1 dangerouslySetInnerHTML={{ __html: props.block.text }}></h1>
      <img src={icon} />
      <div className="gradLineBot" />
    </div>
  </>
);
