import React from "react";
import styles from "./Question.scss";
import { initializeApp } from "firebase/app";

import { getDatabase, ref, set, increment } from "firebase/database";
import { firebaseConfig } from "../helpers.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'

const app = initializeApp(firebaseConfig);

export default (props) => {
  const [showAnswer, setShowAnswer] = React.useState(false);
  const [answer, setAnswer] = React.useState(props.block.answer);
  const [correctAnswerIndex, setcorrectAnswerIndex] = React.useState(false);
  const [feedback, setFeedback] = React.useState(
    props.block.answer == null ? true : false
  );

  const [userAnswerIndex, setUserAnswerIndex] = React.useState(null);

  const [submitted, setSubmitted] = React.useState(false);

  return (
    <>
    
      <br></br>
      <div className="" id={props.block.anchor}>
        <div className="question">

        <h3>{props.block.title}</h3>

        </div>
        <div className="questionBorder">
          <h4 dangerouslySetInnerHTML={{ __html: props.block.question }}></h4>

          <div className="options">
            {props.block.options.map((option, index) => (
              <button
                className={`option ${showAnswer && index == correctAnswerIndex
                  ? "showAnswerFeedback"
                  : ""
                  }
            ${submitted ? "disableClick" : ""}
            ${submitted && showAnswer && index !== correctAnswerIndex && !feedback
                    ? "opacityHalf"
                    : ""
                  }`}
                key={index}
                onClick={() => {
                  setShowAnswer(true);
                  setUserAnswerIndex(index);
                  setcorrectAnswerIndex(index);

                }}
              >
            {!feedback &&
                <span className="letter">
                {!feedback && index == 0 ? "A. " : null}
                {!feedback && index == 1 ? "B. " : null}
                {!feedback && index == 2 ? "C. " : null}
                {!feedback && index == 3 ? "D. " : null}
                </span>
}
                <span>{option}</span>
                {index == answer && showAnswer && submitted ? (
                  <span className="feedbackIcon iconCorrect"><FontAwesomeIcon icon={faCheck} /></span>
                ) : null}
                {index !== answer &&
                  showAnswer &&
                  correctAnswerIndex !== index && submitted &&
                  !feedback ? (
                  <span className="feedbackIcon"><FontAwesomeIcon icon={faXmark} /></span>
                ) : null}
              </button>
            ))}
          </div>

          {showAnswer && !submitted && (

            <button
              className={`option submit`}
              onClick={() => {
                let dbref = ref(
                  getDatabase(app),
                  "/QA/" + props.block.questionNumber + "/" + userAnswerIndex
                );
                set(dbref, {
                  count: increment(1),
                });
                setSubmitted(true);

                if (!feedback) {
                  setcorrectAnswerIndex(props.block.answer);

                }

              }}
            >
              SUBMIT ANSWER
            </button>
          )}
          {/* {submitted && (
            <>
              <div className="thankYou">
                <h3>Thank you for your {feedback ? "feedback" : "answer"}!</h3>

              </div>

              <br />
            </>
          )} */}


          {props.block.footnote ? (
            <div className="footnote">
              <p dangerouslySetInnerHTML={{ __html: props.block.footnote }}></p>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
